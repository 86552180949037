<template>
  <div>
    <!-- <base-header>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Ganadores de tombola del éxito</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header> -->

    <div class="container-fluid mt-4">
      <card class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
        <loading-overlay :active.sync="loading" :is-full-page="true" />

        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0 text-primary">Verificar ganadores</h3>
            </div>
            <!-- <div class="col-6 text-right">
                <base-button
                  :disabled="loading"
                  @click="openModal2()"
                  type="primary"
                  icon
                  size="sm"
                >
                  <span class="btn-inner--icon"
                    ><i class="fas fa-file-excel"></i
                  ></span>
                  <span class="btn-inner--text">Exportar</span>
                </base-button>
              </div> -->
          </div>
        </template>

        <div class="row justify-content-between">
          <div class="col col-md-10 col-sm-6">
            <div class="row px-2">
              <base-input label="Pais:" class="mb-0 pr-1">
                <el-select
                  v-if="!loading1"
                  v-model="country"
                  filterable
                  placeholder="Elige un país"
                >
                  <el-option
                    v-for="option in countries"
                    :key="option.id"
                    :label="option.name"
                    :value="option.name"
                  >
                  </el-option>
                </el-select>

                <div v-else style="height: 50px" class="vld-parent">
                  <loading-overlay
                    :height="30"
                    :active.sync="loading1"
                    :is-full-page="false"
                  />
                </div>
              </base-input>

              <base-input label="Fase:" class="mb-0 pl-1">
                <el-select
                  v-if="!loading1"
                  v-model="cutoff_date"
                  filterable
                  placeholder="Elige una fase"
                >
                  <el-option
                    v-for="op in cutoffdates"
                    :key="op.id"
                    :label="op.phase"
                    :value="op.id"
                  >
                  </el-option>
                </el-select>
                <div v-else style="height: 50px" class="vld-parent">
                  <loading-overlay
                    :height="30"
                    :active.sync="loading1"
                    :is-full-page="false"
                  />
                </div>
              </base-input>

              <base-input label=" " class="mb-0 pt-2">
                <base-button
                  type="button"
                  class="btn btn-primary"
                  native-type="submit"
                  :disabled="validate_sorteo"
                  @click="getWinners()"
                >
                  Ver
                </base-button>
              </base-input>
            </div>
          </div>
        </div>

        <div class="row px-2"></div>
        <div class="container-flid">
          <div class="row">
            <div class="mx-auto">
              <h2 class="text-primary text-center">GANADORES A VERIFICAR</h2>
            </div>
          </div>

          <div class="card">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                v-for="(winner, index) in winners"
                :key="index"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <a class="avatar rounded-circle">
                      <span>{{ index + 1 }}</span>
                    </a>
                  </div>

                  <div class="col ml--2">
                    <h4 class="mb-0">
                      <a href="#!"
                        ><span>{{ winner.name }} </span></a
                      >
                    </h4>
                    <small
                      >Verificado: {{ winner.verified == 0 ? "No" : "Si" }} ,
                      Facturas subidas: {{ winner.bills_uploads }}</small
                    >
                    <br />
                    <small
                      >Pais: {{ winner.country_name }}, Fase:
                      {{ winner.phase }}</small
                    >
                  </div>

                  <div lass="col-auto">
                    <el-tooltip content="Descalificar" placement="top">
                      <a
                        @click="
                          askDeletWinner(index, winner.id, winner.user_id)
                        "
                        class="btn btn-danger btn-sm m-1"
                        data-toggle="tooltip"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </el-tooltip>
                  </div>
                  <!-- <div lass="col-auto">
                    <el-tooltip content="Descalificar" placement="top">
                      <a
                        @click="askDisqualification(index, winner.user_id)"
                        class="btn btn-warning btn-sm m-1"
                        data-toggle="tooltip"
                        ><i class="fa fa-history" aria-hidden="true"></i
                      ></a>
                    </el-tooltip>
                  </div> -->

                  <div lass="col-auto">
                    <el-tooltip content="Contactar" placement="top">
                      <a
                        @click="openModal(winner)"
                        class="btn btn-primary btn-sm m-1"
                        data-toggle="tooltip"
                      >
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </a>
                    </el-tooltip>
                  </div>
                  <div lass="col-auto">
                    <el-tooltip content="Ver historial" placement="top">
                      <a
                        :href="'/admin/bills-user/' + winner.user_id"
                        target="_blank"
                        class="btn btn-info btn-sm m-1 mr-3"
                        data-toggle="tooltip"
                        style="cursor: pointer"
                      >
                        <i class="fas fa-file-invoice" />
                      </a>
                    </el-tooltip>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col">
            <div class="float-right">
              <base-button
                @click="askPostWinners()"
                type="button"
                class="btn btn-success"
                native-type="submit"
                :disabled="validate"
              >
                Publicar
              </base-button>
            </div>
          </div>
        </div>
      </card>

      <!-- Modal para mostrar los datos del ususario ganador -->
      <modal :show.sync="modal">
        <h6 slot="header" class="modal-title">Información de contacto</h6>

        <div class="row">
          <div class="col-12">
            <p>
              <strong class="font-weight-bold">Nombre:</strong>
              {{ usuario.name }}
            </p>
          </div>
          <div class="col-12">
            <p>
              <strong class="font-weight-bold">Email:</strong>
              {{ usuario.email }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p>
              <strong class="font-weight-bold">Identificación:</strong>
              {{ usuario.identification }}
            </p>
          </div>
          <div class="col-12">
            <p>
              <strong class="font-weight-bold">Teléfono:</strong>
              {{ usuario.tel }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <p>
              <strong class="font-weight-bold">Dirección:</strong>
              {{ usuario.address }}
            </p>
          </div>
        </div>
      </modal>

      <!-- Modal2 para mostrar descargar reporte de ganadores con filtros con pais y fase -->
      <modal :show.sync="modal2">
        <h6 slot="header" class="modal-title">Exportar datos</h6>

        <div class="row justify-content-around">
          <base-input label="Pais:" class="mb-0 pr-1">
            <el-select
              v-if="!loading1"
              v-model="country2"
              filterable
              placeholder="Elige un país"
            >
              <el-option
                v-for="option in countries_export_data"
                :key="option.id"
                :label="option.name"
                :value="option.name"
              >
              </el-option>
            </el-select>

            <div v-else style="height: 50px" class="vld-parent">
              <loading-overlay
                :height="30"
                :active.sync="loading1"
                :is-full-page="false"
              />
            </div>
          </base-input>

          <base-input label="Fase:" class="mb-0 pl-1">
            <el-select
              v-if="!loading1"
              v-model="cutoff_date2"
              filterable
              placeholder="Elige una fase"
            >
              <el-option
                v-for="op in cutoffdates_export_data"
                :key="op.id"
                :label="op.phase"
                :value="op.id"
              >
              </el-option>
            </el-select>
            <div v-else style="height: 50px" class="vld-parent">
              <loading-overlay
                :height="30"
                :active.sync="loading1"
                :is-full-page="false"
              />
            </div>
          </base-input>
        </div>
        <div class="row justify-content-around">
          <base-button
            :disabled="validate_export_data"
            @click="exportData()"
            style="top: 50%"
            type="primary"
            size="sm"
            >Exportar</base-button
          >
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
/* import { BasePagination } from "@/components/"; */
import { Tooltip, Select, Option } from "element-ui";
/* import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb"; */
import { Modal } from "@/components";
/* import flatPicker from "vue-flatpickr-component"; */
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es";
import XLSX from "xlsx";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    /* BasePagination, */
    [Tooltip.name]: Tooltip,
    [Select.name]: Select,
    [Option.name]: Option,
    /*  RouteBreadCrumb, */
    Modal,
    /* flatPicker, */
  },

  data() {
    return {
      config: {
        altFormat: "d M, Y",
        altInput: true,
        allowInput: true,
        dateFormat: "m-d-Y",
        locale: Spanish,
      },
      loading: false,
      loading1: false,
      countries: [],
      cutoffdates: [],
      countries_export_data: [],
      cutoffdates_export_data: [],
      cutoff_date: null,
      country: null,
      cutoff_date2: null,
      country2: null,
      winners: [],
      winner: [],
      count: 0,
      //contacto user
      usuario: {
        name: "",
        email: "",
        identification: "",
        tel: "",
        address: "",
      },
      modal: false,
      modal2: false,
      //variables para la descalificaion de usuairos
      excluidos: [],
      nuevo: null,
    };
  },

  computed: {
    validate_sorteo: function () {
      return !this.country || !this.cutoff_date;
    },
    validate: function () {
      return !this.winners.length > 0;
    },
    validate_export_data: function () {
      return !this.country2 || !this.cutoff_date2;
    },
  },

  methods: {
    openModal2() {
      this.modal2 = true;
    },
    openModal(row) {
      this.usuario.name = row.name;
      this.usuario.email = row.email;
      this.usuario.tel = row.tel;
      this.usuario.identification = row.identification;
      this.usuario.address = row.address;
      this.modal = true;
    },
    async getCutOffDate() {
      this.loading1 = true;
      await this.$store
        .dispatch("cutOffDate/list")
        .then(() => {
          this.cutoffdates = this.$store.getters["cutOffDate/list"];
          this.cutoffdates.unshift({ id: -1, phase: "Todos" });
          this.cutoffdates_export_data = _.cloneDeep(this.cutoffdates);
          //this.cutoffdates_export_data.unshift({id:-1, phase:'Todos'});
        })
        .catch((error) => {
          this.sendError("Get cut off date - view winners admin", error, "");
        });
      this.loading1 = false;
    },
    async getCountries() {
      this.loading1 = true;
      await this.$store
        .dispatch("countries/list")
        .then(() => {
          this.countries = this.$store.getters["countries/list"];
          this.countries_export_data = _.cloneDeep(this.countries);
          this.countries_export_data.unshift({ id: -1, name: "Todos" });
          this.countries.unshift({ id: -1, name: "Todos" });
        })
        .catch((error) => {
          this.sendError("Get countries - view winners admin", error, "");
        });
      this.loading1 = false;
    },
    async getWinners() {
      this.loading = true;
      try {
        const params = {
          country: this.country,
          cutOffDate: this.cutoff_date,
        };
        await this.$store
          .dispatch("tombola/viewWinnersVerify", params)
          .then(() => {
            this.winners = this.$store.getters["tombola/getWinners"];
            if (this.winners.length === 0) {
              swal.fire({
                title: `¡Atención!`,
                text: "No hay ningún ganador para este país o fase.",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                icon: "warning",
              });
            }
          });
        this.loading = false;
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
        await this.$store.dispatch("errors/sendError", {
          plataforma: "Tombola del éxito / Clavulin",
          metodo: "View winners admin",
          error: JSON.stringify(error.response),
          data: JSON.stringify({
            country: this.country,
            cutOffDate: this.cutoff_date,
            winners: this.winners,
          }),
        });
        this.loading = false;
        this.$notify({
          type: "danger",
          message: `Lo sentimos, a sucedito un error, inténtelo más tarde.`,
        });
        console.log(e);
      }
    },
    askDeletWinner(item, id_winner, id_user) {
      swal
        .fire({
          title: "¿Está seguro de eliminar a este ganador?",
          confirmButtonText: `Continuar`,
          denyButtonText: `Cancelar`,
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteWinner(item, id_winner, id_user);
          }
        });
    },
    async deleteWinner(item, id_winner) {
      this.loading = true;
      try {
        //se elimna el array, al usuario que seleccionaron

        const params = {
          id: id_winner,
        };
        await this.$store.dispatch("tombola/deleteWinner", params).then(() => {
          const respuesta = this.$store.getters["tombola/deleteResponse"];
          //se coloca al principio de la lista al nuevo ganador
          if (respuesta.result === "success") {
            this.winners.splice(item, 1);
            this.$notify({
              type: "success",
              message: `Ganador eliminado correctamente.`,
            });
          } else {
            this.sendError("Delete winner admin", respuesta, {
              id_user: id_winner,
            });

            this.$notify({
              type: "danger",
              message: `Lo sentimos, no se a podido eliminar al usuario ganador.`,
            });
          }
        });

        this.loading = false;
      } catch (error) {
        await this.$store.dispatch("errors/sendError", {
          plataforma: "Tombola del éxito / Clavulin",
          metodo: "Delete winner admin",
          error: JSON.stringify(error.response),
          data: JSON.stringify({ id_user: id_winner }),
        });
        this.loading = false;
        this.$notify({
          type: "danger",
          message: `Lo sentimos, a sucedito un error, inténtelo más tarde.`,
        });
        console.log(e);
      }
    },

    askPostWinners() {
      swal
        .fire({
          title: "¿Está seguro de publicar estos ganadores para este país?",
          confirmButtonText: `Continuar`,
          denyButtonText: `Cancelar`,
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.postWinners();
          }
        });
    },

    async postWinners() {
      this.loading = true;
      try {
        let winneres = {
          winners: this.winners,
          country: this.country,
          phase: this.cutoff_date,
        };

        await this.$store
          .dispatch("tombola/saveWinnersVerified", winneres)
          .then(() => {
            let respuesta = this.$store.getters["tombola/saveWinners"];

            if (respuesta.get.result === "success") {
              this.winners.length = 0;
              //this.excluidos.length = 0;
              this.$store.dispatch("tombola/vaciar_excluidos");
              this.$notify({
                type: "success",
                message: "Ganadores publicados exitosamente.",
              });
            } else {
              this.$store.dispatch("error/sendError", {
                plataforma: "Tombola del éxito / Clavulin",
                metodo: "Post winners",
                error: respuesta,
                data: JSON.stringify(this.winners),
              });
              this.$notify({
                type: "danger",
                message: "Ha ocurrido un error al publicar los ganadores.",
              });
            }
          });
        this.loading = false;
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
        await this.$store.dispatch("error/sendError", {
          plataforma: "Tombola del éxito / Clavulin",
          metodo: "Post winners",
          error: error.response,
          data: JSON.stringify(this.winners),
        });
        this.loading = false;
        this.$notify({
          type: "danger",
          message: "Ha ocurrido un error al guardar los ganadores.",
        });
      }
    },

    async exportData() {
      this.loading = true;
      const params = {
        cutOffDate: this.cutoff_date2,
        country: this.country2,
      };
      await this.$store.dispatch("tombola/exportWinners", params);
      const response = this.$store.getters["tombola/exportData"];
      if (response.length > 0) {
        let data = XLSX.utils.json_to_sheet(response, {
          header: [
            "country_name",
            "name",
            "email",
            "identification",
            "tel",
            "address",
            "phase",
          ],
        });
        data["A1"].v = "País";
        data["B1"].v = "Usuario";
        data["C1"].v = "Correo";
        data["D1"].v = "Identificación";
        data["E1"].v = "Teléfono";
        data["F1"].v = "Dirección";
        data["G1"].v = "Fase";

        const workbook = XLSX.utils.book_new();
        const filename = `ganadores_${this.country2}_fase_${this.cutoff_date2}`;
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
        this.loading = false;
        this.modal2 = false;
      } else {
        swal.fire({
          title: `¡Atención!`,
          text: "No existen ganadores para este país o fase.",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          icon: "warning",
        });
      }
      this.loading = false;
      this.modal2 = false;
    },
    async sendError(metodo, err, data) {
      await this.$store.dispatch("errores/sendError", {
        plataforma: "Tombola del éxito / Clavulin",
        metodo: metodo,
        error: err,
        data,
      });
    },
  },
  created() {
    this.getCountries();
    this.getCutOffDate();
    //this.getWinners();
  },
};
</script>
<style scoped>
.tam-img {
  width: 200px !important;
}
/* Escritorio extra grande grande */
@media (min-width: 1296px) {
  .borde-tombola {
    position: relative;
    z-index: 2;
  }
  .borde-tombola-2 {
    z-index: 1;
    margin-top: -4rem;
  }
}

/* Escritorio grande */
@media (min-width: 1200px) and (min-width: 1295px) {
  .borde-tombola {
    position: relative;
    z-index: 2;
  }
  .borde-tombola-2 {
    z-index: 1;
    margin-top: -2rem;
  }
}

/* Escritorio mediano o tablet horizontal */
@media (max-width: 1199px) and (min-width: 992px) {
  .borde-tombola {
    position: relative;
    z-index: 2;
  }
  .borde-tombola-2 {
    z-index: 1;
    margin-top: -2rem;
  }
}

/* Escritorio pequeño o tablet vertical */
@media (max-width: 991px) and (min-width: 500px) {
  .borde-tombola {
    position: relative;
    z-index: 2;
  }
  .borde-tombola-2 {
    z-index: 1;
    margin-top: -1rem;
  }
}

/* Movil */
@media (max-width: 499px) {
  .borde-tombola {
    position: relative;
    z-index: 2;
  }
  .borde-tombola-2 {
    z-index: 1;
    margin-top: -0.5rem;
  }
  .tam-img {
    width: 200px impo !important;
  }

  #tx-trasparente .form-group label {
    opacity: 0 !important;
  }
}
</style>
